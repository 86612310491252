import Button from "@mui/material/Button"

const btnStyle = {
  py: "4px",
  px: 2,
  fontSize: ".8rem",
  backgroundColor: "#37e178",
  border: "2px solid #37e178",
  borderRadius: "9999px",
  color: "#000",
  textAlign: "center",
  whiteSpace: "nowrap",
  width: "auto",

  "&:hover": {
    backgroundColor: "#37e178",
    border: "2px solid #999999",
  },
}

const UnloqButton = (props) => {
  return (
    <Button sx={btnStyle} {...props}>
      {props.children}
    </Button>
  )
}

export default UnloqButton
