import { Avatar as MuiAvatar } from "@mui/material"

export const Avatar = (props) => {
  const { alt, ...rest } = props

  function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  return (
    <MuiAvatar
      sx={{
        color: "#fff",
        "text-shadow": "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
        bgcolor: stringToColor(props.alt),
      }}
      alt={alt.toUpperCase()}
      {...rest}
    />
  )
}
