import { Subject } from "../api/models"

export async function getSubjects({
  include = "",
  order = {},
  filters = {},
  page = 1,
  limit = 10,
}) {
  const { data } = await Subject.where(filters)
    .page(page)
    .per(limit)
    .order(order)
    .includes(include)
    .all()

  return data
}
