import { PrivacyTip } from "@mui/icons-material"
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material"
import axios from "axios"
import dayjs from "dayjs"
import { Fragment, useEffect, useRef } from "react"
import { Avatar } from "../react_admin/components/avatars/Avatar"

const CommentsList = ({
  i18n,
  comments,
  setComments,
  currentUserId,
  setReplyComment,
}) => {
  const commentsEnd = useRef(null)

  useEffect(() => {
    if (comments.length > 0) {
      axios
        .patch("/api/v1/comments/mark_read", {
          comment_ids: comments.map((comment) => comment.id),
        })
        .then(() => {
          // do nothing
        })
        .catch(function (error) {
          console.error(error)
        })
      setTimeout(() => {
        commentsEnd.current.scrollIntoView()
      }, 1)
    }
  }, [comments])

  useEffect(() => {
    scrollToBottom()
  }, [comments])

  const scrollToBottom = () => {
    commentsEnd.current.scrollIntoView({ behavior: "smooth" })
  }

  const showPrivacyIcon = (comment, reply) => {
    if (comment.shared == "public" || reply) {
      return
    }
    return (
      <Tooltip title={i18n.t("react.admin.comments.shared." + comment.shared)}>
        <PrivacyTip fontSize="small" />
      </Tooltip>
    )
  }

  const replyTo = (comment) => {
    setReplyComment(comment)
  }

  const includeReplyLink = (comment) => {
    if (comment.repliedComment) {
      return
    }
    return (
      <Typography
        component="span"
        variant="caption"
        onClick={() => {
          replyTo(comment)
        }}
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          height: "16px",
          marginRight: "10px",
        }}
      >
        {i18n.t("react.admin.reply")}
      </Typography>
    )
  }

  const includeDeleteLink = (comment) => {
    if (comment.userId != currentUserId) return

    return (
      <Typography
        component="span"
        variant="caption"
        onClick={() => handleDelete(comment)}
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          height: "16px",
          marginRight: "10px",
        }}
      >
        {i18n.t("react.admin.delete")}
      </Typography>
    )
  }

  const handleDelete = async (comment) => {
    const saved = await comment.destroy()
    if (saved) {
      setComments([])
    }
  }

  const renderListItem = (comment, reply = false) => {
    const itemStyle = reply ? { py: 0 } : { py: 0, mb: 2 }
    const itemTextStyle = reply
      ? { backgroundColor: "#F5F5F5", px: 2, py: 1, ml: 2, borderRadius: "6px" }
      : {}
    const footerStyle = reply
      ? { display: "none", height: "16px" }
      : { height: "16px", marginRight: "1em" }

    return (
      <ListItem alignItems="flex-start" key={comment.id} sx={itemStyle}>
        <ListItemAvatar sx={reply ? { minWidth: 24 } : null}>
          <Avatar alt={comment.userName} src={comment.avatarUrl} />
        </ListItemAvatar>
        <ListItemText
          sx={itemTextStyle}
          primary={
            <Fragment>
              {comment.userName && (
                <Typography
                  sx={{
                    display: "inline",
                    marginRight: "1em",
                    fontWeight: "700",
                    fontSize: "0.75rem",
                  }}
                  component="span"
                >
                  {comment.userName}
                </Typography>
              )}
              <Typography sx={footerStyle} component="span" variant="caption">
                {dayjs(comment.createdAt).format("DD/MM/YYYY")}
              </Typography>
              {includeReplyLink(comment)}
              {includeDeleteLink(comment)}
              {showPrivacyIcon(comment, reply)}
            </Fragment>
          }
          secondary={
            comment.subject?.title?.en
              ? "[" + comment.subject.title.en + "] " + comment.text
              : comment.text
          }
        />
      </ListItem>
    )
  }

  const listContainerStyle = {
    overflowY: "auto",
    height: "calc(80vh - 270px)",

    overflowScrolling: "touch",
    "-webkit-overflow-scrolling": "touch",

    background: `
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) ) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) ) center bottom
  `,

    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 80px, 100% 80px, 100% 28px, 100% 28px",
    backgroundAttachment: "local, local, scroll, scroll",

    scrollbarWidth: "thin",
    scrollbarColor: "#90a4ae transparent",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#90a4ae",
      borderRadius: "6px",
      border: "3px solid transparent",
    },
  }

  return (
    <Box sx={listContainerStyle}>
      <List>
        {comments.map(function (comment) {
          if (comment.repliedComment) {
            const repliedComment = comments.find(
              (note) => comment.repliedCommentId.toString() == note.id
            )

            return (
              <Box
                key={comment.hashid}
                sx={{
                  border: 1,
                  borderRadius: "7px",
                  borderColor: "grey.200",
                  marginTop: "5px",
                }}
              >
                <Box>{renderListItem(repliedComment, true)}</Box>
                <Box sx={{ pl: 5 }}>{renderListItem(comment, false)}</Box>
              </Box>
            )
          } else {
            return renderListItem(comment)
          }
        })}
        <div ref={commentsEnd}></div>
      </List>
    </Box>
  )
}

export default CommentsList
