import SendIcon from "@mui/icons-material/Send"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Comment } from "../api/models"
import { getComments } from "../repository/comments_repository"
import { getSubjects } from "../repository/subjects_repository"
import UnloqButton from "./unloq_button"

const CommentsForm = ({
  i18n,
  setComments,
  commentable_id,
  commentable_type,
  replyComment,
  setReplyComment,
  showSharedSelectbox,
}) => {
  const [shareds] = useState(["private", "public"])
  const [shared, setShared] = useState(shareds[1])
  const [subjects, setSubjects] = useState([])
  const [subject, setSubject] = useState("")
  const [text, setText] = useState("")

  const sharedSelectStyle = () => {
    const style = { width: "100%" }

    return style
  }

  useEffect(() => {
    if (subjects.length == 0) {
      fetchSubjects().catch(console.error)
    }
  }, [subjects])

  useEffect(() => {
    setSubject("")
    setShared(shareds[1])
  }, [commentable_id, shareds])

  const fetchSubjects = async () => {
    const fetchedSubjects = await getSubjects({ filters: {} })
    if (fetchedSubjects.length > 0) {
      setSubjects(fetchedSubjects)
    }
  }

  const handleSubjectChange = (event: SelectChangeEvent) => {
    setSubject(event.target.value as string)
  }

  const handleSharedChange = (event: SelectChangeEvent) => {
    setShared(event.target.value as string)
  }

  const refetchComments = async () => {
    const filters = {
      commentable_id: commentable_id,
      commentable_type: commentable_type,
    }

    const fetchedComments = await getComments({ filters: filters })
    if (fetchedComments.length > 0) {
      setComments(fetchedComments)
      setText("")
      setReplyComment("")
      setSubject("")
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const commentModel = new Comment({
      text: text,
      subjectId: subject,
      shared: shared,
      commentableId: commentable_id,
      commentableType: commentable_type,
      repliedCommentId: replyComment.id,
    })
    const saved = await commentModel.save()
    if (saved) {
      refetchComments()
    }
  }

  const showReplyComment = (replyComment) => {
    if (!replyComment || replyComment.text.length == 0) {
      return
    }

    return (
      <Box sx={{ mt: 1, px: 2, display: "flex", flexDirection: "column" }}>
        {replyComment.userName}
        <TextField
          value={replyComment.text}
          minRows={2}
          fullWidth
          multiline
          disabled
          sx={{ backgroundColor: "grey.200" }}
        />
      </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box display="flex" sx={{ gap: 2 }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="subject">
              {i18n.t("react.admin.comments.subject")}
            </InputLabel>
            <Select
              labelId="subject"
              value={subject}
              label={i18n.t("react.admin.comments.subject")}
              onChange={handleSubjectChange}
            >
              <MenuItem
                key="other"
                sx={{ fontSize: "small", fontStyle: "italic" }}
              >
                {i18n.t("react.admin.other")}
              </MenuItem>
              {subjects.map(function (subject) {
                return (
                  <MenuItem
                    key={subject.id}
                    value={subject.id}
                    sx={{ fontSize: "small" }}
                  >
                    {subject.title.en}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {showSharedSelectbox && (
            <FormControl sx={sharedSelectStyle}>
              <InputLabel id="shared">
                {i18n.t("react.admin.comments.shared.title")}
              </InputLabel>
              <Select
                labelId="shared"
                value={shared}
                label={i18n.t("react.admin.comments.shared.title")}
                onChange={handleSharedChange}
              >
                {shareds.map(function (shared) {
                  const sharedTranslation = i18n.t(
                    `react.admin.comments.shared.${shared}`
                  )
                  return (
                    <MenuItem
                      key={shared}
                      value={shared}
                      sx={{ fontSize: "small" }}
                    >
                      {sharedTranslation}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </Box>
        {showReplyComment(replyComment)}
        <TextField
          label={i18n.t("react.admin.comments.title")}
          value={text}
          minRows={3}
          placeholder={i18n.t("react.admin.comments.title_placeholder")}
          sx={{ my: 2 }}
          fullWidth
          multiline
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setText(event.target.value)
          }}
        />
        <Box display="flex" justifyContent="end">
          <UnloqButton type="submit" startIcon={<SendIcon />}>
            {i18n.t("react.admin.send")}
          </UnloqButton>
        </Box>
      </form>
    </Box>
  )
}

export default CommentsForm
